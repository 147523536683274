@mixin generate-attributes($attr, $attr-name, $start-count: 0, $attr-count: 10, $attr-steps: 10, $unit: 'px')
  $attr-list: null
  @for $i from $start-count through $attr-count
    $attr-value: $attr-steps * $i

    .#{$attr-name}#{$attr-value}
      #{$attr}: #{$attr-value}#{$unit} !important

    $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma)

  #{$attr-list} //append style to all classes


@include generate-attributes('margin-top', 'g-mt-', 0, 12, 4)
@include generate-attributes('margin-right', 'g-mr-', 0, 12, 4)
@include generate-attributes('margin-bottom', 'g-mb-', 0, 12, 4)
@include generate-attributes('margin-left', 'g-ml-', 0, 12, 4)
@include generate-attributes('margin-top', 'g-mt-', 0, 20, 5)
@include generate-attributes('margin-right', 'g-mr-', 0, 20, 5)
@include generate-attributes('margin-bottom', 'g-mb-', 0, 20, 5)
@include generate-attributes('margin-left', 'g-ml-', 0, 20, 5)

@include generate-attributes('padding-top', 'g-pt-', 0, 12, 4)
@include generate-attributes('padding-right', 'g-pr-', 0, 12, 4)
@include generate-attributes('padding-bottom', 'g-pb-', 0, 12, 4)
@include generate-attributes('padding-left', 'g-pl-', 0, 12, 4)
@include generate-attributes('padding-top', 'g-pt-', 0, 20, 5)
@include generate-attributes('padding-right', 'g-pr-', 0, 20, 5)
@include generate-attributes('padding-bottom', 'g-pb-', 0, 20, 5)
@include generate-attributes('padding-left', 'g-pl-', 0, 20, 5)

@include generate-attributes('opacity', 'g-opacity-', 0, 10, 10, '%')
