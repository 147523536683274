.prilo-icon
  width: 1rem
  min-width: 1rem
  height: 1rem
  background-repeat: no-repeat

.prilo-icon-edit
  background-image: url("/assets/icons/15px/edit.svg")

.prilo-icon-duplicate
  background-image: url("/assets/icons/15px/duplicate.svg")

.prilo-icon-eye-open
  background-image: url("/assets/icons/15px/eye-open.svg")
