@import "variables"

@mixin global-field-underline
  border-top: 0px
  border-left: 0px
  border-right: 0px
  border-bottom: 1px solid
  border-radius: 0px
  border-color: $mono-elements
  box-shadow: none

@mixin input-field-underline
  @include global-field-underline
  padding: 4px 0px
  margin: 10px 0px 0px 0px

@mixin global-invalid-underline
  color: $secondary-red
  border-color: $secondary-red

@mixin remove-focus-decoration
  &:focus, &:active
    outline: 0 none
    outline-offset: 0
    //box-shadow: none
    //border: 0
