// Text truncate
// Requires inline-block or block for proper styling

@mixin text-truncate()
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.text-truncate
  @include text-truncate
