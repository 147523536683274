/* You can add global styles to this file, and also import other style files */
@import "fonts"
@import "form"
@import "typography"
@import "distances"
@import "components-global"
@import "generated-attributes"
@import "text"
@import "prilo-icons"
@import "helpers"
@import "responsive"
@import "ngb-toooltip"

body,html
  margin: 0
  padding: 0
  background-color: $mono-ghost

html body
  @include body($weight-regular)
  background: $mono-ghost
  color: $primary-dark-purple

  .alert
    color: $secondary-red

  a
    color: $primary-dark-purple

input::-ms-reveal,
input::-ms-clear
  display: none

html, body
  scrollbar-gutter: stable
