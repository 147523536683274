@import "colors"

$main-font: "Objektiv Mk2"
$secondary-font: sans-serif
$page-signin-container-width: 480px
$page-registration-container-width: 970px
$page-container-width: 1170px
$page-header-height: 100px
$min-page-width: 1270px

$weight-bold: 700
$weight-regular: 400
$weight-thin: 300

// paddings containers
$padding-big: 100px
$padding-regular: 60px
$padding-medium: 40px
$padding-small: 30px
$padding-smaller: 10px
$padding-no: 1px
