@import "breakpoints"

html
  // PAGE CONTAINER WIDTH
  --page-container-width: 1170px

  @include media-breakpoint-down(lg)
    --page-container-width: 960px

  @include media-breakpoint-down(md)
    --page-container-width: 704px

  @include media-breakpoint-down(sm)
    --page-container-width: 343px

  @include media-breakpoint-down(xs)
    --page-container-width: 100%

html
  // PAGE SIGN IN CONTAINER WIDTH
  --page-signin-container-width: 480px

  @include media-breakpoint-down(lg)
    --page-signin-container-width: 464px

  @include media-breakpoint-down(md)
    --page-signin-container-width: 520px

  @include media-breakpoint-down(sm)
    --page-signin-container-width: 343px

  @include media-breakpoint-down(xs)
    --page-signin-container-width: 100%

html
  // PAGE REGISTRATION CONTAINER WIDTH
  --page-registration-container-width: 970px

  @include media-breakpoint-down(lg)
    --page-registration-container-width: 794px

  @include media-breakpoint-down(md)
    --page-registration-container-width: 520px

  @include media-breakpoint-down(sm)
    --page-registration-container-width: 343px

  @include media-breakpoint-down(xs)
    --page-registration-container-width: auto



