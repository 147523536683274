$primary-white: #FFFFFF
$primary-yellow: #FFEB7C
$primary-orange: #FDA961
$primary-purple: #BA8AFF
$primary-white-filter: brightness(0) invert(1)
$primary-purple-filter: invert(73%) sepia(69%) saturate(4135%) hue-rotate(217deg) brightness(102%) contrast(101%)
$primary-green-filter: invert(68%) sepia(4%) saturate(3837%) hue-rotate(91deg) brightness(90%) contrast(92%)
$set-to-black-filter: brightness(0) saturate(100%)
$primary-green: #60B17F
$primary-dark-purple: #1A0837
$primary-dark-purple-filter: invert(6%) sepia(49%) saturate(5761%) hue-rotate(260deg) brightness(75%) contrast(102%)
$primary-dark-purple-opacity: #1A083766
$mono-white: #FFFFFF
$mono-ghost: #F7F7F7
$mono-elements: #EBEBEB
$mono-font: #A9A8AB
$mono-font-2: #F
$mono-font-filter: invert(74%) sepia(6%) saturate(100%) hue-rotate(219deg) brightness(90%) contrast(93%)
$mono-graphite: #5C5A61
$secondary-vanilla: #FFFCEB
$secondary-lilac: #F0E7FE
$secondary-salvia: #E3F5EA
$secondary-salmon: #FFF3F0
$secondary-red: #FA6646
$secondary-red-filter: invert(49%) sepia(39%) saturate(959%) hue-rotate(323deg) brightness(99%) contrast(98%)
$secondary-blue: #6EB9FE
$secondary-sky: #DFF0FF
$secondary-plum: #4D2492

