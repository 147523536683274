@import "variables"
@import "breakpoints"

.no-distances
  padding: 0

.a-page-registration-container
  padding: 60px 100px 40px 100px
  @include media-breakpoint-down(lg)
    padding: 40px 60px
  @include media-breakpoint-down(sm)
    padding: 32px
  @include media-breakpoint-down(xs)
    padding: 24px
  margin: 20px auto
  @include media-breakpoint-down(xs)
    margin-left: 0
    margin-right: 0
  .description-regular
    padding-top: 10px
    padding-bottom: 10px

.a-page-container
  .no-distances
    padding: 0
    margin: 0
  H3
    padding: 0
    margin: 25px 0px 5px 0px

  .body-large-bold
    margin-top: 30px
    margin-bottom: 15px
    padding: 0
  .body-bold
    margin-top: 15px
    margin-bottom: 15px
    padding: 0
  .formfield-line
    margin-top: 15px
    margin-bottom: 15px
  p-radiobutton
    margin-top: 4px
    margin-bottom: 4px
  .a-page-container-white-rounded
    margin-top: 40px
    margin-bottom: 15px
  .regular-yellow
    margin: 0 30px
  .regular-yellow.without-padding
    margin: 0
  .p-radiobutton-label
    padding: 0 0.5rem

  .a-table-simple
    .p-datatable
      margin: 30px 0

  .p-datatable
    .body-bold
      margin: 0
      padding: 0

.shipment-order

.carrier-offer
  .description-regular
    padding-bottom: 0px
