@import "typography-mixins"
@import "breakpoints"

H1
  @include header-1
  //styleName: desktop/H1;

  margin-top: 60px
  margin-bottom: 20px

H2
  //styleName: desktop/H2;
  @include header-2

H3
  //styleName: desktop/H3;
  @include header-3

H4
  //styleName: desktop/H4;
  @include header-4

.body-large-bold
  //styleName: desktop/body large bold;
  @include body-large($weight-bold)

.body-large
  //styleName: desktop/body large;
  @include body-large($weight-regular)

.body-bold
  //styleName: desktop/body bold;
  @include body($weight-bold)

.body-bold-underline a,
a.body-bold-underline,
.body-bold-underline
  @include body($weight-bold)
  text-decoration-line: underline

.description-bold
  //styleName: desktop/description bold;
  @include description($weight-bold)

.description-regular
  //styleName: desktop/description regular;
  @include description($weight-regular)

.description-bold-underline a,
a.description-bold-underline,
.description-bold-underline
  //styleName: desktop/description bold underline;
  @include description($weight-bold)
  text-decoration-line: underline

.description-underline,
.description-underline a,
a.description-underline
  //styleName: desktop/description underline;
  @include description($weight-regular)
  text-decoration-line: underline
  color: $mono-font

.text-button
  //styleName: desktop/text button;
  font-weight: 700
  font-size: 11px
  line-height: 16px
  display: flex
  align-items: center
  text-transform: uppercase

.note
  //styleName: desktop/note;
  @include note

.note-underline a,
a.note-underline,
.note-underline
  //styleName: desktop/note underline;
  @include note
  text-decoration-line: underline

// containers
.a-page-card
  display: flex
  flex-direction: column
  align-items: center

  .a-page-header
    width: var(--page-container-width)
    height: $page-header-height
    position: relative
  .a-page-container
    width: var(--page-container-width)

  .a-page-container-white-rounded
    background: $mono-white
    border-radius: 20px

  .a-page-container-unbordered
    @include a-page-container-unbordered

  .a-shadow-container,
  .a-shadow-gray-container
    margin: 0 0 40px 0
    padding: $padding-no $padding-small $padding-medium $padding-big
    box-shadow: 0px 10px 20px -10px rgba(26, 8, 55, 0.07)
    border-radius: 10px

  .a-shadow-gray-container
    background: $mono-ghost
    border: 3px solid $mono-white
    box-sizing: border-box

  // registration component
  .a-page-registration-container-header
    text-align: center

    h1
      margin-top: 20px
      margin-bottom: 20px
      @include media-breakpoint-down(lg)
        margin-bottom: 16px
      @include media-breakpoint-down(sm)
        margin-bottom: 8px
  .a-page-registration-container
    width: var(--page-registration-container-width)
    background: $mono-white
    box-shadow: 0px 60px 60px -20px rgba(26, 8, 55, 0.1)
    border-radius: 20px
    @include media-breakpoint-down(sm)
      border-radius: 10px

// colors
.primary-yellow
  color: $primary-yellow
.primary-purple
  color: $primary-purple
.primary-green
  color: $primary-green
.primary-dark-purple
  color: $primary-dark-purple
.primary-dark-purple-opacity
  color: $primary-dark-purple-opacity
.mono-white
  color: $mono-white
.mono-ghost
  color: $mono-ghost
.mono-elements
  color: $mono-elements
.mono-font
  color: $mono-font
.mono-font-2
  color: $mono-font-2
.mono-graphite
  color: $mono-graphite
.secondary-vanilla
  color: $secondary-vanilla
.secondary-lilac
  color: $secondary-lilac
.secondary-salvia
  color: $secondary-salvia
.secondary-salmon
  color: $secondary-salmon
.secondary-red
  color: $secondary-red
.secondary-blue
  color: $secondary-blue
.secondary-sky
  color: $secondary-sky

.primary-white-filter
  filter: $primary-white-filter

.primary-dark-purple-filter
  filter: $primary-dark-purple-filter

.primary-green-filter
  filter: $primary-green-filter

.mono-font-filter
  filter: $mono-font-filter

// background
// colors
.primary-yellow-background
  background-color: $primary-yellow
.primary-purple-background
  background-color: $primary-purple
.primary-green-background
  background-color: $primary-green
.primary-dark-purple-background
  background-color: $primary-dark-purple
.primary-dark-purple-opacity-background
  background-color: $primary-dark-purple-opacity
.mono-white-background
  background-color: $mono-white
.mono-ghost-background
  background-color: $mono-ghost
.mono-elements-background
  background-color: $mono-elements
.mono-font-background
  background-color: $mono-font
.secondary-vanilla-background
  background-color: $secondary-vanilla
.secondary-lilac-background
  background-color: $secondary-lilac
.secondary-salvia-background
  background-color: $secondary-salvia !important
.secondary-salmon-background
  background-color: $secondary-salmon
.secondary-red-background
  background-color: $secondary-red
.secondary-sky-background
  background-color: $secondary-sky

// cursor
.pointer
  cursor: pointer

.no-wrap-text
  white-space: nowrap

.force-wrap-text
  overflow-wrap: break-word

.word-break-all
  word-break: break-all

.line-height-initial
  line-height: initial

.align-self-end
  align-self: flex-end
