@import 'stream-chat-angular/src/assets/styles/v2/scss/index.scss'
@import 'variables'
@import 'typography-mixins'

.str-chat.messaging
  background-color: transparent

  *
    font-family: $main-font
    color: $primary-dark-purple

  .str-chat__load-more-button
    background: $primary-yellow
    color: $primary-dark-purple
    font-size: 12px
    font-weight: bold
    padding: 10px 20px
    border-radius: 7px
    border: none
    align-self: center
    cursor: pointer

    &:focus
      outline: 0px none

  &.str-chat-channel-list
    overflow-y: auto
    box-sizing: border-box
    display: flex
    flex-direction: column

    .str-chat__channel-list-messenger
      background-color: transparent
      min-width: 0

      &__main
        padding: 0
        display: flex
        flex-direction: column

  .str-chat__channel-preview-messenger
    box-shadow: none
    display: flex
    flex-direction: column
    text-decoration: none
    border: 3px solid transparent
    position: relative
    padding: 10px
    width: 100%
    text-align: left
    background: transparent
    cursor: pointer

    &--name
      font-size: 14px
      font-weight: 700
      padding: 0
      margin: 0

    &--description
      font-size: 11px
      margin-bottom: 5px
      padding: 0
      margin: 0

    &--last-message
      font-size: 11px
      font-weight: 700
      color: $mono-font
      max-width: 100%
      margin: 0

    &--unread-count
      display: none
      position: absolute
      right: 5px
      top: 5px
      width: 20px
      height: 20px
      font-size: 10px
      background: $primary-purple
      color: white
      align-items: center
      justify-content: center
      border-radius: 50%

    &--active
      background: $mono-ghost
      border: 3px solid white
      box-shadow: 0px 10px 20px -10px rgba(26, 8, 55, 0.07)
      border-radius: 10px

    &--unread
      .str-chat__channel-preview-messenger--last-message
        font-weight: bold
        color: inherit

      .str-chat__channel-preview-messenger--unread-count
        display: flex

  &.str-chat-channel
    .str-chat__main-panel
      padding: 0

    .str-chat__header-livestream
      background-color: $mono-ghost
      border: 3px solid white
      border-radius: 10px 10px 0px 0px
      box-shadow: 0px 10px 20px -10px rgba(26, 8, 55, 0.07)
      padding: 16px

      &-left
        &--title
          @include header-4
          a
            text-decoration: none
            &:hover
              text-decoration: underline

        &--description
          font-size: 11px
          margin-bottom: 5px

        &--members
          &, span
            font-size: 12px
            color: $mono-graphite

          &__member
            &::before
              content: ', '

            &:first-child::before
              content: ''

          &__watching
            &::before
              content: ' | '

            &:first-child::before
              content: ''

  .str-chat__message
    &-text
      &-inner
        font-size: 14px
        border: 0px none
        p
          line-height: 24px

    &-attachment

      &--image
        background: transparent !important

      img.str-chat__message-attachment--img
        width: auto

      [icon="file"]
        margin-right: 5px

        svg
          display: none

        &::before
          @include pi
          @include pi-content('\e9a8')
          font-size: 25px


    &--deleted
      &-inner
        background: $mono-ghost
        color: $mono-font

    .str-chat__avatar
      &-fallback
        background: $primary-purple
        color: white
        padding-top: 0.04em

    &--me
      .str-chat__message-bubble
        background-color: $mono-elements

      .str-chat__avatar
        &-fallback
          background: $primary-dark-purple

      &.str-chat__message--deleted
        .str-chat__message--deleted-inner
          background: $mono-elements

    &-simple-status
      .str-chat__avatar
        &-fallback
          background: $primary-purple

      [icon="delivered-icon"]
        svg
          display: none

        &::before
          @include pi
          @include pi-content('\e909')
          color: $primary-purple
          font-size: 10px
          width: 16px
          line-height: 16px

    &--announcement
      justify-content: center

      .str-chat__message-text
        text-align: center
        font-size: 12px
        color: $mono-font

  .str-chat__tooltip
    color: white
    &, &::after
      background: $primary-purple

  .str-chat__list-notifications
    background: white
    margin: 0
    padding: 5px 0

  .str-chat__message-textarea textarea
    background: white
    border: 1px solid $mono-elements
    box-shadow: 0px 10px 20px -10px rgba(26, 8, 55, 0.07)
    padding: 18px
    padding-left: 20px
    min-height: (2 * (18 + 1) + 20) * 1px
    line-height: 20px

  .str-chat__input-flat
    .rfu-file-upload-button
      margin-top: -4px

  .str-chat__message-notification-scroll-down
    outline: 0px none
    &::before
      border: 2px solid $primary-purple
      border-width: 0 0 2px 2px

  .str-chat__input-flat-wrapper
    align-items: flex-end

  .str-chat__send-button
    height: 66px
    display: initial
    cursor: pointer

    svg
      display: none

    &::before
      @include pi
      @include pi-content('\e9ca')
      color: $primary-purple
      font-size: 20px

  .rfu-file-previewer
    [icon="file"]
      svg
        display: none

      &::before
        @include pi
        @include pi-content('\e9a8')

    &__close-button
      font-size: 0
      display: flex

      &::before
        @include pi
        @include pi-content('\e90b')
        font-size: 16px

  .str-chat__custom-notification
    border: 0px none

    &.notification-success
      background: $secondary-salvia
      &, *
        color: $primary-green

    &.notification-error
      background: $secondary-salmon
      &, *
        color: $secondary-red







