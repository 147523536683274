@import "primeflex"
@import "typography-mixins"
@import "form-mixins"
@import "primeng-default"
@import "stream-chat"
@import "text"
@import "breakpoints"

.description-bold
  .p-inputtext
    @include description($weight-bold)

  .p-dropdown
    @include description($weight-bold)

  .p-autocomplete
    @include description($weight-bold)

// -- steps
.a-page-registration-container-header
  .p-steps
    ul
      overflow: hidden

    .p-steps-item:first-of-type:before, .p-steps-item:last-of-type:after
      visibility: hidden

    .p-steps-item:before, .p-steps-item:after
      content: " "
      border-top: 1px solid #A9A8AB
      width: 50%
      top: 19px
      opacity: 1
      position: absolute
      margin-top: 0
      display: block

    .p-steps-item:before
      left: auto
      right: calc(50% + 30px)

    .p-steps-item:after
      left: calc(50% + 30px)
      right: auto

    .p-steps-item:first-of-type:before
      width: 50%
      margin-left: 50%

    .p-steps-item:last-of-type:before
      width: 50%

    .p-steps-current:before, .p-steps-visited:before, .p-steps-visited:after
      border-top: 1px solid $primary-purple


    @include media-breakpoint-down(sm)
      .p-steps-title
        display: none

      .p-steps-current
        .p-steps-title
          display: inline
          max-width: 130px

    .p-steps-item
      .p-menuitem-link:focus,
      .p-menuitem-link:not(.p-disabled):focus
        box-shadow: none

      .p-menuitem-link
        @include body($weight-bold)
        background: $mono-ghost

        .p-steps-number
          @include header-4
          background: $mono-ghost
          color: $mono-font
          min-width: 40px
          height: 40px
          border: 1px solid $mono-font

        .p-steps-title
          color: $mono-font
          font-weight: 700
          white-space: normal
          padding: 0 12px

    .p-steps-item[aria-selected='true'],
    .p-steps-item.valid
      .p-steps-number
        color: $primary-purple
        background: $secondary-lilac
        border: 1px solid $primary-purple

      .p-steps-title
        color: $primary-purple

    .p-steps-item.p-steps-current
      .p-steps-number
        color: $mono-white
        background: $primary-purple

      .p-steps-title
        color: $primary-purple

.a-page-registration-container
  // -- common form fields
  .p-error
    @include global-invalid-underline
    padding-top: 0
    display: block

  input.warning-border-color
    border-bottom: 1px solid
    @include global-invalid-underline

  .warning-border-color
    .p-component,
    .p-inputtext,
    .p-dropdown
      border-bottom: 1px solid
      @include global-invalid-underline

    .p-password
      .p-inputtext
        border-bottom: 0

  .p-component,
  .p-inputtext,
  .p-dropdown
    color: $primary-dark-purple

  form .ng-touched.ng-invalid
    @include global-invalid-underline

  // -- input
  .p-inputtext
    @include input-field-underline

  .p-inputtext:enabled:hover
    border-bottom: 1px solid
    border-color: $primary-purple

  .p-inputtext:enabled:focus
    box-shadow: none
    border-bottom: 1px solid
    border-color: $mono-elements

  .p-inputtext.ng-dirty.ng-invalid
    border-bottom: 1px solid
    @include global-invalid-underline

  // -- dropdown/combobox
  .p-dropdown,
  .p-autocomplete
    @include global-field-underline

    .p-inputtext
      border: 0

    .p-inputtext:enabled:hover
      border: 0

    .p-inputtext:enabled:focus
      border: 0

    .p-inputtext.ng-dirty.ng-invalid
      border: 0

    .p-dropdown-trigger
      width: auto

  .p-dropdown:not(.p-disabled):hover
    border-bottom: 1px solid
    border-color: $primary-purple

  .p-dropdown:not(.p-disabled).p-focus
    box-shadow: none
    border-bottom: 1px solid
    border-color: $mono-elements

  p-dropdown.ng-touched.ng-invalid > .p-dropdown,
  p-autocomplete.ng-touched.ng-invalid > .p-autocomplete
    @include global-invalid-underline

  // -- password
  .p-password
    width: 100%

// a-panel --------------------------------

.a-panel
  .p-panel
    border: 0
    box-sizing: border-box
    box-shadow: 0px 40px 40px -20px rgba(26, 8, 55, 0.1)
    margin-bottom: 20px
    border-radius: 20px

    .p-panel-content
      @include a-page-container-default
      border: 3px solid $mono-white
      border-top: 0px
      color: $primary-dark-purple
      border-radius: 0 0 20px 20px

    .p-panel-header
      padding: 0 6px 0 0
      border: 0
      background: $secondary-lilac
      border-radius: 10px 10px 0px 0px
      color: $primary-dark-purple

      h2, h3, h4
        margin: 10px 30px
        padding-top: 4px
        line-height: 26px

      .body-bold
        margin: 10px 30px
        line-height: 26px

      .p-panel-header-icon
        color: $primary-dark-purple
        border-radius: 0
        transition: none
        padding: 0 32px
        margin: 0

        &:enabled:hover
          background-color: transparent

        &:enabled:focus
          box-shadow: none

        &.p-panel-toggler
          .pi
            padding: 0 8px
            &::before
              content: '\00a0'
            &.pi-plus
              background: url(/assets/icons/10px/chevron-hidden.svg) center center no-repeat
            &.pi-minus
              background: url(/assets/icons/10px/chevron.svg) center center no-repeat


      .a-right-border,
      .a-right-border:hover
        border-right: 1px solid $mono-white

  .p-col-1, .p-col-2, .p-col-3, .p-col-4, .p-col-5, .p-col-6, .p-col-7, .p-col-8, .p-col-9, .p-col-10, .p-col-11, .p-col-12
    padding: 0

  &.a-panel-collapsed
    .p-panel
      .p-panel-header
        background: $mono-white
        box-shadow: 0px 10px 20px -10px rgba(26, 8, 55, 0.07)
        border-radius: 10px

        .a-right-border,
        .a-right-border:hover
          border-right: 1px solid $mono-elements

  &.less-padding
    .p-panel
      .p-panel-content
        padding-right: $padding-small

        .unless-padding
          margin-right: $padding-big - $padding-small

  &.smaller-padding
    .p-panel
      .p-panel-content
        padding: $padding-smaller + 4px $padding-smaller $padding-smaller + 4px $padding-smaller



// p-table
.p-datatable
  table
    border-collapse: separate !important
    border-spacing: 0 1rem
    width: 100%

  .p-datatable-thead > tr > th
    @include description($weight-bold)
    color: $primary-dark-purple
    border: none
    background-color: transparent
    padding: 0.5rem
    vertical-align: bottom
    &.p-sortable-column
      color: inherit !important
      .p-sortable-column-icon
        color: inherit !important
      &:hover, &:focus
        background-color: transparent
        border: none
        box-shadow: none

  .p-datatable-tbody > tr,
  .p-datatable-tfoot > tr
    background: $primary-white
    border: none
    box-shadow: 0px 10px 20px -10px rgba(17, 17, 17, 0.07)
    border-radius: 7px
    &.separator
      background: transparent
      box-shadow: none
      height: 1rem

    &.expandable
      &.expanded
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
        & > td
          &:first-child
            border-bottom-left-radius: 0
          &:last-child
            border-bottom-right-radius: 0

    &.expansion
      border-top-left-radius: 0
      border-top-right-radius: 0
      & > td
        &:first-child
          border-top-left-radius: 0
        &:last-child
          border-top-right-radius: 0

    &.mono-ghost
      height: 36px
      &, td:first-child, td:last-child
        background: $mono-ghost
        box-shadow: initial

    &.transparent
      background: transparent

      &, td:first-child, td:last-child
        background: transparent
        box-shadow: initial

  .p-datatable-tbody, .p-datatable-tfoot
    & > tr > td
      @include description($weight-regular)
      border: none
      padding: 0.5rem

  .p-datatable-tbody > tr > td.description-bold,
  .p-datatable-tbody > tr.description-bold > td,
  .p-datatable-tfoot > tr > td.description-bold,
  .p-datatable-tfoot > tr.description-bold > td
    font-weight: $weight-bold

  .p-datatable-tbody, .p-datatable-tfoot
    & > tr > td.description-regular
      font-weight: $weight-regular !important

  .p-datatable-tbody, .p-datatable-tfoot
    & > tr > td:last-child
      background: $primary-white
      box-shadow: 0px 10px 20px -10px rgba(17, 17, 17, 0.07)
      border-top-right-radius: 7px
      border-bottom-right-radius: 7px
  .p-datatable-tbody, .p-datatable-tfoot
    & > tr > td:first-child
      background: $primary-white
      box-shadow: 0px 10px 20px -10px rgba(17, 17, 17, 0.07)
      border-top-left-radius: 7px
      border-bottom-left-radius: 7px

  .p-datatable.p-datatable-sm
    .p-datatable-thead > tr > th
      padding: 0 0 0 0.4rem

    .p-datatable-thead > tr > th:first-child
      padding: 0 0 0 1rem
    .p-datatable-thead > tr > th:last-child
      padding: 0 1rem 0 0

    .p-datatable-tbody, .p-datatable-tfoot
      & > tr > td
        padding: 0.5rem 0 0.5rem 0.4rem

    .p-datatable-tbody, .p-datatable-tfoot
      & > tr > td:first-child
        padding: 0.5rem 0 0.5rem 1rem
    .p-datatable-tbody, .p-datatable-tfoot
      & > tr > td:last-child
        padding: 0.5rem 1rem 0.5rem 0

  // expandable tables
  &.p-datatable-collapse
    table
      border-collapse: collapse !important




// TODO: remove this class (will need fixing typography in some tables)
.a-table-simple
  .p-datatable
    margin: 30px


.no-padding-row
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td
    padding: 0 0.5rem

.fixed-height-row
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr
    height: 50px

.lilac-table-header
 .p-datatable .p-datatable-tbody > tr, .p-datatable.p-datatable-sm .p-datatable-thead > tr > th
  background: $secondary-lilac
  vertical-align: middle
  height: 50px
  //border-radius: 10px 0 0 0
 .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td
  background: $mono-white
 .p-datatable.p-datatable-sm .p-datatable-tbody > tr
  height: 36px

// end of p-table

.p-divider.p-divider-horizontal
  margin: 2rem 0

.white
  .p-divider.p-divider-horizontal::before
    border-top: 2px solid white
    width: 90%
    margin-left: 10%
    margin: 1rem 0

  .p-divider .p-divider-content
    background-color: $mono-elements

  .p-divider.p-divider-horizontal
    padding: unset

  .p-divider.p-divider-horizontal .p-divider-content
    padding: unset

.p-button.p-button-text
  &, &:disabled, &:enabled
    &, &:hover, &:active
      color: $primary-dark-purple

.p-rating .p-rating-icon.pi-star
  color: $primary-dark-purple

// p-fileUpload
.regular-form-file .p-button
  @include body($weight-bold)
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-radius: unset
  margin: 1px 4px 7px 7px
  border: 0px
  width: 100%

  .p-button-label
    padding: 6px 0 0 0

p-fileupload.regular-form-file
    width: 100%

.regular-form-file
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 10px 10px
    background: transparent
    color: $primary-dark-purple
  .p-fileupload-choose,
  .p-fileupload-choose:not(.p-disabled):hover
    flex-direction: row-reverse
    padding: 0
    background: transparent
    color: $primary-dark-purple
    .p-button-label
      text-align: left
      @include description($weight-bold)
      color: $mono-font

  .p-fileupload-choose:not(.p-disabled):hover .p-button-label,
  .p-button:enabled:hover .p-button-label
    text-decoration: underline

  .p-button:enabled:focus
    box-shadow: unset

  .p-button:disabled
    opacity: 0.4

  .p-fileupload-choose
    @include remove-focus-decoration
    .pi
      display: inline-block
      background: url("/assets/icons/15px/upload.svg") center center no-repeat
      &::before
        content: '\00a0\00a0'

p-fileUpload
  &.hide-messages
    p-messages
      display: none

// p-button
.btn-icon-only
    background: none
    border: none
    margin: 0
    padding: 0
    cursor: pointer

.regular-text .p-button
  @include body($weight-bold)
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-radius: unset
  border: 0px
  width: 100%

  .p-button-label
    padding: 6px 0 0 0

.regular-text.small > .p-button
  @include body($weight-bold)
  font-size: 11px
  display: inline-flex
  align-items: center
  border-radius: unset
  border: 0px
  width: auto
  padding: 0 !important

  .p-button-label
    padding: 0

.uppercase > .p-button
  .p-button-label
    text-transform: uppercase

.underline-text-button .p-button
  margin: 0
  padding: 0
  border: 0
  font-weight: $weight-regular
  font-size: 11px
  text-decoration: underline

.underline-text-button
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    background: transparent
    color: $mono-font
  .p-button:disabled
    opacity: 0.4

p-fileupload.regular-text
    width: 100%

.regular-text
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 10px 10px
    background: transparent
    color: $primary-dark-purple
  .p-fileupload-choose,
  .p-fileupload-choose:not(.p-disabled):hover
    flex-direction: row-reverse
    padding: 0
    background: transparent
    color: $primary-dark-purple
    .p-button-label
      text-align: left

  .p-fileupload-choose:not(.p-disabled):hover .p-button-label,
  .p-button:enabled:hover .p-button-label
    text-decoration: underline

  .p-button:enabled:focus
    box-shadow: unset

  .p-button:disabled
    opacity: 0.4

a.regular-dark,
a.regular-yellow,
a.medium-yellow,
a.small-dark,
a.small-yellow,
.regular-dark .p-button,
.small-dark .p-button,
.small-yellow .p-button,
.small-text .p-button,
.small-white .p-button,
.small-plum .p-button,
.medium-yellow .p-button,
.regular-yellow .p-button,
.large-yellow .p-button
  @include body($weight-bold)
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-radius: 7px
  border: 0
  width: 100%
  white-space: nowrap

  .p-button-label
    padding: 4px 0 0 0

a.thin-yellow,
.thin-yellow .p-button
  @include body($weight-bold)
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-radius: 7px
  border: 0

  .p-button-label
    padding: 2px 0 0 0

a.small-yellow
  padding: 10px 40px
  background: $primary-yellow
  color: $primary-dark-purple
  width: 175px
  height: 40px
  text-decoration: none
  cursor: pointer
  white-space: nowrap

a.regular-dark
  padding: 10px 20px
  background: $primary-dark-purple
  color: $mono-white
  text-decoration: none
  cursor: pointer

.regular-dark
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 10px 20px
    background: $primary-dark-purple
    color: $mono-white

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-dark-purple

  .p-button:disabled
    opacity: 0.2

  &.smaller-padding
    .p-button,
    .p-button:enabled:hover,
    .p-button:enabled:active
      padding: 10px 12px

.small-dark,
.small-plum,
.small-white
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 8px 15px
    background: $primary-dark-purple
    color: $mono-white

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-dark-purple

  .p-button:disabled
    opacity: 0.2

a.small-dark
  display: inline-block
  padding: 8px 15px
  background: $primary-dark-purple
  color: $mono-white
  cursor: pointer
  width: fit-content
  text-decoration: none

a.small-yellow
  padding: 10px 40px
  background: $primary-yellow
  color: $primary-dark-purple
  width: 175px
  height: 40px
  cursor: pointer
  width: fit-content
  text-decoration: none

  &:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

.small-plum
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    background: $secondary-plum

.small-white
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-button:enabled:focus
    background: $mono-white
    color: $primary-dark-purple
    box-shadow: 0px 10px 20px -10px rgba($primary-dark-purple, 0.1)

.small-yellow
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 10px 40px
    background: $primary-yellow
    color: $primary-dark-purple
    width: 175px
    height: 40px

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

  .p-button:disabled
    opacity: 0.2

a.small-yellow
  padding: 10px 40px
  background: $primary-yellow
  color: $primary-dark-purple
  width: 175px
  height: 40px
  cursor: pointer
  width: fit-content
  text-decoration: none

  &:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

.small-text
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 10px 40px
    background: transparent
    color: $primary-dark-purple
    width: 175px
    height: 40px

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

  .p-button:disabled
    opacity: 0.2

.thin-yellow
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 10px 40px
    background: $primary-yellow
    color: $primary-dark-purple
    height: 40px

  &.smaller-padding
    .p-button,
    .p-button:enabled:hover,
    .p-button:enabled:active
      padding: 10px 20px

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

  .p-button:disabled
    opacity: 0.2

a.thin-yellow
  padding: 10px 40px
  background: $primary-yellow
  color: $primary-dark-purple
  height: 40px
  text-decoration: none
  cursor: pointer

.medium-yellow
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 14px 30px
    background: $primary-yellow
    color: $primary-dark-purple
    height: 48px

  &.smaller-padding
    .p-button,
    .p-button:enabled:hover,
    .p-button:enabled:active
      padding: 10px 20px

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

  .p-button:disabled
    opacity: 0.2

a.medium-yellow
  padding: 14px 30px
  background: $primary-yellow
  color: $primary-dark-purple
  height: 48px
  text-decoration: none
  cursor: pointer

a.regular-yellow,
.regular-yellow
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 20px 40px
    background: $primary-yellow
    color: $primary-dark-purple
    width: 183px
    height: 60px

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

  .p-button:disabled
    opacity: 0.2

.large-yellow
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active
    padding: 10px 40px
    background: $primary-yellow
    color: $primary-dark-purple
    height: 60px
    border-color: $primary-yellow

  .p-button:enabled:focus
    box-shadow: 0 0 0 0.2rem $primary-yellow

  .p-button:disabled
    opacity: 0.2

  .p-component
    font-weight: bold

.shrink > .p-button
  width: auto !important

.inline > .p-button
  display: inline-flex

.regular-text.inline-compact > .p-button
  &, &:active, &:hover
    display: inline-flex
    width: auto
    padding: 0
    margin: 0

.custom-content > .p-button
  // add padding to what's most likely the icon
  img:first-child
    padding-right: 0.25rem

  // hide the auto-inserted button label which is empty anyway but adds a needless space
  .p-button-label + .p-button-label
    display: none

.no-wrap
  .p-button
    white-space: nowrap
    width: auto !important

.comments
  .regular-yellow .p-button,
  .regular-yellow .p-button:enabled:hover,
  .regular-yellow .p-button:enabled:active
    padding: 10px 30px

.a-inputswitch

  .p-inputswitch
    margin-top: 7px
    width: 30px
    @include media-breakpoint-down(sm)
      width: 34px
    height: 16px
    @include media-breakpoint-down(sm)
      height: 20px

  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider
    background: transparent


  .p-inputswitch .p-inputswitch-slider
    border-width: 1px
    border-color: $primary-dark-purple
    border-style: solid
    background: transparent

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider
    border-width: 1px
    border-color: $primary-purple
    border-style: solid
    background: transparent

  .p-inputswitch.p-inputswitch .p-inputswitch-slider:before
    background-color: transparent
    border-width: 1px
    border-color: $primary-dark-purple
    border-style: solid
    height: 16px
    @include media-breakpoint-down(sm)
      height: 20px
    width: 16px
    @include media-breakpoint-down(sm)
      width: 20px
    margin-top: -8px
    @include media-breakpoint-down(sm)
      margin-top: -10px
    box-sizing: border-box
    left: -1px

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before
    background-color: $primary-purple
    border-color: $primary-purple
    transform: translate(calc(30px - 100%))
    @include media-breakpoint-down(sm)
      transform: translate(calc(34px - 100%))


.a-inputswitch-big

  .p-inputswitch
    width: 40px
    height: 20px

  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider
    background: transparent

  .p-inputswitch .p-inputswitch-slider
    border-width: 1px
    border-color: $primary-purple
    border-style: solid
    border-radius: 12.5px
    background: transparent
    height: 20px

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider
    border-width: 1px
    border-color: $primary-purple
    border-style: solid
    background: transparent

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover
    background: transparent

  .p-inputswitch.p-inputswitch .p-inputswitch-slider:before
    background-color: transparent
    border-width: 1px
    border-color: $primary-purple
    border-style: solid
    height: 18px
    width: 18px
    margin-top: -10px
    margin-left: -5px

  .p-inputswitch.p-inputswitch .p-inputswitch-slider:after
    background-color: $primary-purple
    content: ''
    display: block
    border-radius: 50%
    height: 14px
    width: 14px
    margin-top: 2px
    margin-left: 2px
    transition-duration: 0.2s

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:after
    margin-left: 22px

.a-no-margin-divider > .p-divider.p-divider-horizontal, .a-no-margin-divider > .p-divider.p-divider-vertical
  margin: 0

.a-no-padding-divider > .p-divider.p-divider-horizontal, .a-no-padding-divider > .p-divider.p-divider-vertical
  padding: 0

p-card.p-pt-0
  .p-card-body
    padding-top: 0

  .p-card-content
    padding-top: 0

p-card.p-pr-0
  .p-card-body
    padding-right: 0

  .p-card-content
    padding-right: 0

p-card.p-pb-0
  .p-card-body
    padding-bottom: 0

  .p-card-content
    padding-bottom: 0

p-card.p-pl-0
  .p-card-body
    padding-left: 0

  .p-card-content
    padding-left: 0

p-card.rounded > .p-card
    border-radius: 10px

p-card.overflow-hidden > .p-card
    overflow: hidden

.p-dropdown-panel .p-dropdown-items .p-dropdown-item
  @include description($weight-bold)
  color: $primary-dark-purple

.p-dropdown-label
  @include text-truncate


.sort-icon
  .pi-sort-amount-up-alt::before, .pi-sort-amount-down::before
    content: url("/assets/icons/10px/chevron-sort-down.svg")
  .pi-sort-alt::before
    content: url("/assets/icons/10px/chevron-sort-up-down.svg")
  .pi-sort-amount-up-alt
    transform: scaleY(-1)

.a-no-background .p-dropdown
  background: none
  border: none
  border-radius: 0
  box-shadow: none

  .p-dropdown-label
    padding: 0
    background: none
    border: none
    border-radius: 0
    box-shadow: none

.a-low-height-button
  button.p-button, button.p-button:enabled:hover
    margin: 0
    padding-top: 2px
    padding-bottom: 0
    padding-right: 0
    @include description($weight-bold)

.no-padding-button.p-button-text
  padding: 0

.p-tabview .p-tabview-nav
  box-sizing: border-box
  border-radius: 7px
  border: 3px solid $mono-ghost
  padding-left: 3px
  padding-right: 3px
  margin-bottom: 20px

  @include media-breakpoint-down(sm)
    padding: 0

.p-tabview .p-tabview-nav li
  flex-grow: 1

.p-tabview .p-tabview-nav li a
  outline: none

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
  background: $mono-ghost
  color: $primary-purple
  border-radius: 7px
  border: 3px solid $primary-purple
  box-sizing: border-box

  img
    filter: $set-to-black-filter $primary-purple-filter

p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled) .p-tabview-nav-link,
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link
  background: $mono-ghost
  color: $primary-dark-purple

.p-tabview .p-tabview-nav li .p-tabview-nav-link
  border: solid white 10px
  border-width: 2px 0 2px 0
  display: flex
  align-items: center
  justify-content: center
  padding: 8px
  height: 100%

  @include media-breakpoint-up(xl)
    padding: 16px

  @include media-breakpoint-down(lg)
    align-items: flex-start

  @include media-breakpoint-down(sm)
    padding: 6px

.p-tabview .p-tabview-panels
  background: $mono-ghost
  padding: 0

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus
  box-shadow: none

input
  @include text-truncate

.regular-text .p-button .p-button-label
  padding: 0

.small-padding-link-button.p-button-link
  padding-top: 3px
  padding-bottom: 3px
  padding-left: 0

.pi
  &--center
    vertical-align: middle


// dialogs
.p-dialog
  .p-dialog-header-icons
    position: absolute
    right: 20px
    top: 20px

.p-dialog-mask.p-component-overlay
  overflow-y: auto

.p-dialog-mask.overflow-y-initial
  overflow-y: initial

// input groups
// Fix after upgrade primeng from 12 to 14 - input group app-shipment-order-cars
.p-inputgroup
  p-autocomplete
    &.p-inputwrapper
      & >.p-component
        width: initial

// p-panel-toggleable
// Fix after upgrade primeng from 12 to 14 - ADAMPOL-3126
// wait for fix 14.0.2
.p-panel-toggleable:not(.p-panel-expanded) > .p-toggleable-content
  overflow: hidden !important

// datatable-resizable
.p-datatable-resizable
  .p-datatable-tbody>tr>td
    text-overflow: ellipsis

// only icon p button
@include media-breakpoint-down(sm)
  .p-button-regular-only-icon-sm
    .p-button
      width: 48px !important
      height: 48px !important

      .p-button-icon
        margin: 0

      .p-button-label
        display: none

// toast
.p-toast-top-right
  top: 100px !important
  right: 0 !important

.p-toast
  opacity: 1
  .p-toast-message
    &.p-toast-message-success,
    &.p-toast-message-error
      border: none
      border-bottom-left-radius: 7px
      border-top-left-radius: 7px

    &.p-toast-message-success
      background: $secondary-salvia

    &.p-toast-message-error
      background: $secondary-salmon

    .p-toast-message-content
      padding: 20px
      display: flex
      align-items: center


