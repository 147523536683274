@import "typography-mixins"
@import "form-mixins"

.p-component
  @include body($weight-regular)
.p-inputtext
  @include body($weight-regular)
  width: 100%
.p-dropdown
  @include body($weight-regular)
  width: 100%
.p-calendar
  @include body($weight-regular)
  width: 100%
.p-autocomplete
  @include body($weight-regular)
  width: 100%
textarea
  resize: none

div[validation]
  small
    display: inline-block

.p-error
  @include global-invalid-underline

// -- primeflex
.p-grid
  margin: 0

.p-col-1, .p-col-2, .p-col-3, .p-col-4, .p-col-5, .p-col-6, .p-col-7, .p-col-8, .p-col-9, .p-col-10, .p-col-11, .p-col-12
  padding: 0.5rem 0 0 0

// -- checkbox

.p-checkbox
  border: 1px solid $mono-font
  height: 18px
  min-height: 18px
  width: 18px
  min-width: 18px
  justify-content: center
  align-items: center
  margin: 0
  padding: 0
  box-sizing: border-box
  border-radius: 5px

  &:not(.p-checkbox-disabled)
    border-color: $primary-dark-purple

.p-checkbox.p-checkbox-checked
  border: 1px solid $primary-purple

.p-checkbox .p-checkbox-box
  position: relative
  border: 0
  width: 100%
  height: 100%
  padding: 1.5px
  background-clip: border-box
  border-radius: 7px
  box-sizing: border-box
  background-color: $mono-white

.pi-check:before
  display: none

.p-highlight .p-checkbox-icon

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:not(.p-highlight):hover

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight
  &, &:hover
    border-color: $primary-purple
    background-color: $primary-purple
    padding: 2px
    background-clip: content-box
    border-radius: 5px

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus
  box-shadow: none
  border-color: $primary-purple

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box
  @include global-invalid-underline

// radiobutton
p-radiobutton
  align-items: flex-end
.p-radiobutton
  .p-radiobutton-box:not(.p-disabled)
    border: 1px solid $primary-dark-purple

  .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover
    border-color: $primary-purple

  .p-radiobutton-box:not(.p-disabled).p-focus
    box-shadow: none
    border-color: $primary-purple

  .p-radiobutton-box.p-highlight,
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover
    border-color: $primary-purple
    background: $mono-white

  .p-radiobutton-box .p-radiobutton-icon
    background-color: $primary-purple

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box
  @include global-invalid-underline

// -- input
.p-inputtext
  border: 0
  transition: none
  appearance: none
  background: $mono-white
  box-shadow: 0px 10px 20px -10px rgba(26, 8, 55, 0.07)
  border-radius: 7px
  //padding: 10px 8px 8p x 8px

.p-inputtext:enabled:hover
  border: 0

.p-inputtext:enabled:focus
  box-shadow: none
  border: 0

.p-inputtext.ng-dirty.ng-invalid
  @include global-invalid-underline

// -- dropdown/combobox
.p-dropdown
  border: 0
  transition: none
  background: $mono-white
  box-shadow: 0px 10px 20px -10px rgba(26, 8, 55, 0.07)
  border-radius: 7px

.p-dropdown:not(.p-disabled):hover
  border: 0

.p-dropdown:not(.p-disabled).p-focus
  box-shadow: none
  border: 0

.p-dropdown.p-dropdown-clearable .p-dropdown-label
  padding-right: 1.5rem


.p-dropdown .p-dropdown-label.p-placeholder
  color: #6c757d


.p-dropdown .p-dropdown-trigger
  color: $primary-dark-purple
  border-bottom-right-radius: 3px


// autocomplete
.p-autocomplete
  @include description($weight-bold)
  .p-inputtext
    border-right: 0
  .p-button
    background: $mono-white
    border: 0
    color: $primary-dark-purple
    @include description($weight-bold)
  .p-button:enabled:active,
  .p-button:enabled:hover
    background: $mono-white
    color: $primary-dark-purple
    border: 0
  .p-button:focus
    box-shadow: none


// p-dialog
.p-dialog
  @include body($weight-regular)
  box-shadow: 0px 60px 60px -20px rgba(26, 8, 55, 0.1)
  border-radius: 20px
  .p-dialog-content, .p-dialog-header, .p-dialog-header .p-dialog-header-icon
    color: $primary-dark-purple
  .p-dialog-header
    border-top-left-radius: 20px
    border-top-right-radius: 20px
  .p-dialog-content, .p-dialog-content:last-of-type
    border-bottom-right-radius: 20px
    border-bottom-left-radius: 20px

.p-dialog-mask.p-component-overlay
  --maskbg: #{$primary-dark-purple-opacity}

// p-datatable/p-table
.p-datatable
  .p-datatable-tbody > tr > td
    color: $primary-dark-purple
  .p-datatable-thead > tr > th
    color: $primary-dark-purple
  .p-datatable-foot > tr > th
    color: $primary-dark-purple

ngb-tooltip-window
  .tooltip-inner
    @include description($weight-regular)
    background: $secondary-lilac
    box-shadow: 0px 60px 60px -20px rgba(26, 8, 55, 0.1)
    border-radius: 10px

    color: $primary-dark-purple

    flex: none
    order: 0
    align-self: stretch
    flex-grow: 1
    margin: 10px 0px 0px 20px
    padding: 16px

.table-tooltip
  ngb-tooltip-window
    .tooltip-inner
      @include body($weight-regular)
      background: #EBEBEB
      border-radius: 0px 0px 7px 7px
      color: #1A0837
      padding: 10px 24px 10px 10px
      margin: 0

// p-panel backgrounde
.mono-white-background
  div.p-panel-content
    background-color: $mono-white
.mono-ghost-background,
  div.p-panel-content,
  .p-dialog .p-dialog-mask,
  .p-dialog .p-dialog-header,
  .p-dialog .p-dialog-content
    background-color: $mono-ghost

// divders
.mono-elements .p-divider.p-divider-horizontal:before
  border-top: 1px solid $mono-elements

.secondary-red .p-divider.p-divider-horizontal:before
  border-top: 1px solid $secondary-red

.primary-purple .p-divider.p-divider-horizontal:before
  border-top: 1px solid $primary-purple

.mono-font .p-divider.p-divider-horizontal:before
  border-top: 1px solid $mono-font

.mono-white .p-divider.p-divider-horizontal:before
  border-top: 1px solid $mono-white

// checkbox/radiobutton
.p-field-checkbox, .p-field-radiobutton
  & > label
    line-height: inherit

.p-checkbox-label
  line-height: initial

// button
.p-button-label
  line-height: initial

// paginator
.p-paginator
  .p-paginator-prev, .p-paginator-next, .p-paginator-first, .p-paginator-last
    color: $primary-dark-purple
    min-width: initial
    margin: 0
    padding: 0 7px

  .p-paginator-pages
    margin: 0 25px

    .p-paginator-page
      @include text-pagination
      color: $primary-dark-purple
      min-width: initial
      margin: 0
      padding: 0 7px

      &.p-highlight
        color: $primary-dark-purple
        font-weight: $weight-bold
        background: transparent
        text-decoration: underline
