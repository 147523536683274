@import "variables"
@import "breakpoints"

@mixin main-typography-fonts
  font-family: $main-font, $secondary-font
  font-style: normal

@mixin header-1
  //styleName: desktop/H1;
  @include main-typography-fonts
  font-weight: $weight-bold
  font-size: 40px
  line-height: 50px

  //styleName: mobile/H1;
  @include media-breakpoint-down(sm)
    font-size: 30px
    line-height: 43px

@mixin header-2
  //styleName: desktop/H2;
  @include main-typography-fonts
  font-weight: $weight-bold
  font-size: 30px
  line-height: 43px
  @include media-breakpoint-down(sm)
    font-size: 22px
    line-height: 32px

@mixin header-3
  //styleName: desktop/H3;
  @include main-typography-fonts
  font-weight: $weight-bold
  font-size: 20px
  line-height: 29px

  //styleName: mobile/H3;
  @include media-breakpoint-down(sm)
    font-size: 18px

@mixin header-4
  //styleName: desktop/H4;
  @include main-typography-fonts
  font-weight: $weight-bold
  font-size: 18px
  line-height: 26px

@mixin body($weight)
  @include main-typography-fonts
  font-weight: $weight
  font-size: 14px
  line-height: 20px
  //styleName: mobile/body
  @include media-breakpoint-down(sm)
    font-size: 12px
    line-height: 17px

@mixin body-large($weight)
  @include main-typography-fonts
  font-weight: $weight
  font-size: 16px
  line-height: 23px

@mixin description($weight)
  //styleName: desktop/description bold;
  @include main-typography-fonts
  font-weight: $weight
  font-size: 11px
  line-height: 16px

@mixin text-pagination
  @include main-typography-fonts
  font-size: 11px

@mixin text-button
  //styleName: desktop/text button;
  @include main-typography-fonts
  font-weight: $weight-bold
  font-size: 11px
  line-height: 16px
  &:hover
    text-decoration: underline

@mixin note
  //styleName: desktop/note;
  @include main-typography-fonts
  font-weight: $weight-thin
  font-size: 8px
  line-height: 10px

// containers
@mixin a-page-container-unbordered
  margin: 0
  padding: $padding-medium $padding-no $padding-medium $padding-big

@mixin a-page-container-default
  margin: 0
  padding: $padding-no $padding-big $padding-no $padding-big
  @include media-breakpoint-down(lg)
    padding: $padding-no 36px
  @include media-breakpoint-down(md)
    padding: $padding-no 24px
  @include media-breakpoint-down(sm)
    padding: $padding-no 12px

@mixin pi-content($icon)
  --webkit-backface-visibility: hidden
  backface-visibility: hidden
  content: $icon
  text-align: center

@mixin pi($icon: false)
  font-family: 'primeicons'
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  display: inline-block
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  @if($icon)
    &::before
      @include pi-content($icon)
