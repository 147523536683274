@import "form-mixins"
@import "typography-mixins"

::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
  @include body($weight-regular)
  font-size: 14px
  color: $mono-font
:-ms-input-placeholder //Internet Explorer 10-11
  @include body($weight-regular)
  font-size: 14.1px
  color: $mono-font
::-ms-input-placeholder //Microsoft Edge
  @include body($weight-regular)
  font-size: 14.1px
  color: $mono-font

input[type='password'],
input[type='text']
  @include body($weight-regular)
  width: 100%
