/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * objektiv-mk2:
 *   - http://typekit.com/eulas/00000000000000007735b570
 *   - http://typekit.com/eulas/00000000000000007735b576
 *   - http://typekit.com/eulas/00000000000000007735b581
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-08-03 13:53:33 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=tii3ahv&ht=tk&f=26978.26980.26983&a=8325877&app=typekit&e=css")

@font-face
  font-family: "Objektiv Mk2"
  src: url("https://use.typekit.net/af/1d94f1/00000000000000007735b570/30/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/1d94f1/00000000000000007735b570/30/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff"),  url("https://use.typekit.net/af/1d94f1/00000000000000007735b570/30/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("opentype")
  font-display: auto
  font-style: normal
  font-weight: 300


@font-face
  font-family: "Objektiv Mk2"
  src: url("https://use.typekit.net/af/986a34/00000000000000007735b576/30/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/986a34/00000000000000007735b576/30/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/986a34/00000000000000007735b576/30/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n4&v=3") format("opentype")
  font-display: auto
  font-style: normal
  font-weight: 400


@font-face
  font-family: "Objektiv Mk2"
  src: url("https://use.typekit.net/af/069915/00000000000000007735b581/30/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/069915/00000000000000007735b581/30/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/069915/00000000000000007735b581/30/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("opentype")
  font-display: auto
  font-style: normal
  font-weight: 700

.tk-objektiv-mk2
  font-family: "Objektiv Mk2", sans-serif
