.height-100
  height: 100%

.height-auto
  height: auto

.relative
  position: relative

.absolute
  position: absolute

.currency-price-prilo
  display: inline-block

  &::first-letter
    padding-right: 0.2em

.visibility-hidden
  visibility: hidden

.pointer-events-none
  pointer-events: none

.flex-grow-1
  flex-grow: 1

.align-self-end
  align-self: flex-end

.text-decoration-none
  text-decoration: none

.width-auto
  width: auto
