@import "typography-mixins"
@import "form-mixins"

// field wrapper -----------------------------
.formfield-wrapper-line
  padding: 12px 30px

.field-wrapper,
.field-wrapper-one-line-disabled

  input[type='text'],
  input[type='password'],
  textarea
    @include body($weight-bold)

  input[type='text'],
  input[type='password'],
  textarea,
  .p-inputnumber-input
    box-shadow: none
    background-color: $mono-white
    border: 0
    margin: 0
    padding: 0
    color: $primary-dark-purple
    width: 100%
    @include remove-focus-decoration

  input[type='text'],
  input[type='password']
    height: 32px
    line-height: 32px

  textarea
    line-height: 24px
    padding: (32px - 24px) / 2 0

  ::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $mono-font
    opacity: 1 // Firefox
  :-ms-input-placeholder //Internet Explorer 10-11
    color: $mono-font
  ::-ms-input-placeholder //Microsoft Edge
    color: $mono-font
  .p-dropdown
    box-shadow: none
  .p-inputtext
    box-shadow: none
  .p-inputnumber
    .p-inputnumber-button.p-button
      background: $mono-white
      border: 0
      color: $primary-dark-purple
      width: 1rem
    .pi
      font-size: 0.8rem

.field-wrapper-one-line
  input[type='text'],
  input[type='password'],
  .p-inputnumber-input
    @include description($weight-bold)
    width: 100%
    line-height: 32px

  textarea
    line-height: 24px
    padding: (32px - 24px) / 2 0

  .p-dropdown
    .p-inputtext
      @include description($weight-bold)
      height: 32px
      line-height: 32px
      padding: 0
      margin: 0
      color: $primary-dark-purple

      &:not(.p-dropdown-filter)
        width: 0

  &.field-wrapper-auto-width
    .p-dropdown
    .p-inputtext
      &:not(.p-dropdown-filter)
        width: auto

  ::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
    @include description($weight-regular)
  :-ms-input-placeholder //Internet Explorer 10-11
    @include description($weight-regular)
  ::-ms-input-placeholder //Microsoft Edge
    @include description($weight-regular)

  .placeholder-bold
    ::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-weight: $weight-bold
    :-ms-input-placeholder //Internet Explorer 10-11
      font-weight: $weight-bold
    ::-ms-input-placeholder //Microsoft Edge
      font-weight: $weight-bold

.field-wrapper-one-line-disabled
  input[type='text'],
  input[type='password'],
  textarea
    background-color: $mono-ghost
  .p-disabled,
  .p-component:disabled
    opacity: unset

.field-wrapper textarea:focus-visible
  border: none
  outline: none






// END - field wrapper -----------------------------

.tooltip, .tooltip-positioning
  z-index: 100


.order-list-refresh
  .p-card
    &-content
      padding: 0
      padding-left: 1rem !important


.field-wrapper-a
  label
    &.content
      align-items: baseline !important
  .field-wrapper-icon
    @include description(400)
  .field-wrapper-label
    @include description(400)
  .field-wrapper-text
    @include description(700)

// BACKGROUND TITLE
.background-title-container
  h2
    @include media-breakpoint-down(xl)
      font-size: 24px !important
    @include media-breakpoint-down(lg)
      font-size: 20px !important

  & > *:not(.background-title)
    z-index: 1


  .background-title
    position: absolute
    background: $secondary-lilac
    height: 100%
    z-index: 0
